<template>
    <div class="container-fluid">
        <nav-header :navItems="[
            {
                name: 'Configuration',
                link: {
                    name: 'Job Configuration',
                    params: {  sandboxId: `${selectedSandbox}`, jobId: `${$route.params.jobId}` },
                },
            },
            {
					name: 'Meters',
					link: {
						name: 'Job Meters',
						params: {
							sandboxId: `${selectedSandbox}`,
							jobId: `${$route.params.jobId}`,
						},
					},
					disable: !showMetersTab,
			},
            {
					name: 'Override',
					link: {
						name: 'Job Override',
						params: {
							sandboxId: `${selectedSandbox}`,
							jobId: `${$route.params.jobId}`,
						},
					},
					disable: !showOverrideTab,
				},	
            {
                name: 'Run History',
                link: {
                    name: 'Job Run History',
                    params: {  sandboxId: `${selectedSandbox}`, jobId: `${$route.params.jobId}` },
                },
            },
            {
                name: `${$route.params.executionId}`,
                link: {
                    name: 'Job Execution Details',
                    params: {  sandboxId: `${selectedSandbox}`, jobId: `${$route.params.jobId}`, executionId: ` ` }
                },
                closeTab: true,
                disable: true
            },
            {
                name: 'Promotions',
                link: {
                    name: 'Job Promotions',
                    params: {  sandboxId: `${selectedSandbox}`, jobId: `${$route.params.jobId}` }
                },
                disable: !isProdV2Sandbox
            },

            
        ]" pretitle="aqfer Marketing Data Platform" :breadcrumbTitle=true @nav-close-clicked="closeNav">
            <template v-slot:breadcrumb-title>
                <nav aria-label="breadcrumb" class="header-title">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a @click="closeClick" href="#" class="h1">Jobs</a></li>
                        <li class="breadcrumb-item active h1" aria-current="page">{{ $route.params.jobId }}</li>
                    </ol>
                </nav>
            </template>
            <template v-slot:header-button-content>
                <button
                    v-if="!hideRunJobBtn && isProdV2Sandbox"
                    @click="runBtnClick"
                    class="btn btn-primary"
                    :disabled="isButtonDisabled"
                >
                    <div
                        v-if="runClicked"
                        class="spinner-border spinner-border-sm"
                        role="status"
                    >
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div v-else>
                        <slot></slot>
                        <i
                            v-if="isButtonDisabled"
                            class="fa fa-circle-exclamation"
                            title="Please fix lint errors"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                        ></i>
                        Run Job
                    </div>
                </button>
                <button
                    v-if="showRevertPromotion && isAccessByUserRole(promotionAdminRoles)"
                    class="btn btn-danger"
                    @click="onRevertClick"
                >
                    <div><slot></slot>Revert</div>
                </button>
                <button
                    v-if="showCancelJobBtn"
                    class="btn btn-secondary"
                    @click="setCancelJob"
                >
                    <div
                        v-if="runClicked"
                        class="spinner-border spinner-border-sm"
                        role="status"
                    >
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div v-else><slot></slot>Cancel Job</div>
                </button>
                &nbsp; 
                <button @click="closeClick" class="btn btn-secondary lift"> <i class="fa fa-arrow-left me-1"></i> Back to
                    job list </button>
            </template>

        </nav-header>

        <div>
            <router-view 
                :editMode="editMode"
                :runJobTrigger="runJobTrigger"
                :cancelJobTrigger="cancelJobTrigger"
                v-on:setRunClick="setRunClick"
                v-on:hideRunJob="setHideRunJob"
                v-on:showCancelJob="setShowCancelJob"
                v-on:promotionList="setPromotionList"
                v-on:setShowRevertPromotion="setShowRevertPromotion"
                :job="job"
                :override="override"
                :meters="meters"
                ></router-view>
        </div>

    </div>
</template>

<script>
import NavHeader from "../../components/NavHeader.vue";
import { mapActions } from "vuex";
export default {
    name: "JobDetail",
    components: { NavHeader },
    data() {
        return {
            job: {},
            override:null,
            meters:null,
            error: "",
            selectedSandbox: this.$route.params.sandboxId,
            successMessage: "",
            editMode: false,
            jobs: this.$store.state.amdp.jobs,
            navigate: this.$router,
            runJobTrigger: false,
            cancelJobTrigger: false,
            runClicked: false,
			isButtonDisabled: false,
            cancelJob: false,
            hideRunJobBtn: false,
            showCancelJobBtn: false,
            showOverrideTab: false,
            showMetersTab: false,
            promotionList:[],
            showRevertPromotion: false,
            promotionAdminRoles:[1,3],
        };
    },
    computed: {
        isProdV2Sandbox() { //hidePromotions
            let prodv2Sandboxes = this.$store.state.sandbox.prodV2Sandboxes;
            prodv2Sandboxes = prodv2Sandboxes.filter((sandbox => sandbox.name === this.selectedSandbox))
            return prodv2Sandboxes.length? true: false;
        }
    },
    async beforeMount() {
        this.$store.dispatch("sandbox/setSandboxJobRuns", null); // Assigning null initially to override state
        this.$store.dispatch(
            "sandbox/setCurrentSandbox",
            this.$route.params.sandboxId
        );        
        if(!this.jobs.length) {    
            await this.$store.dispatch("amdp/fetchJobs", this.$route.params.sandboxId);      
            await this.fetchJob();
        } else {
            
            await this.fetchJob();
        }        
    },  
    methods: {
        ...mapActions({
            readJob: "amdp/readJob",
            readOverrideFile: "sandbox/readOverrideFile",
            showModal: "showModal",
        }),
        async fetchJob() {
            let response;
            let self = this;
            try {
                response = await this.readJob({sandboxName:this.$route.params.sandboxId,jobName:this.$route.params.jobId});
                this.job = response.data;
                if(this.job.meters){
					this.meters = this.job.meters
					this.showMetersTab = true;
				}else{
					this.meters = null;
					this.showMetersTab = false;
				}
                this.snapshot = JSON.parse(JSON.stringify(this.job)); // deep clone
                this.$store.dispatch("sandbox/fetchSandboxJobRuns", {jobName: this.job.name, limit:this.$store.state.sandbox.executionsListLimit});
            } catch (error) {
                if (error.response.data.error) this.error = error.response.data.error;
                else this.error = error.message;
            } finally {
                if(this.isProdV2Sandbox)
                    this.fetchOverrideFile()
            }
            
        },
        async fetchOverrideFile() {
				let response;
				try {
					response = await this.readOverrideFile(
						this.$route.params.jobId
					);
					this.override = await response.data;
					if(this.override)						
						this.showOverrideTab= true;
					else						
						this.showOverrideTab= false;

				} catch (error) {
					this.showOverrideTab= false;
					if (error.response.data.error)
						this.error = error.response.data.error;
					else this.error = error.message;
				}

			},
        redirect() {
            this.navigate.push({
                name: "Jobs",
            });
        },
        runBtnClick() {
            this.runClicked = true;
            this.runJobTrigger = !this.runJobTrigger;
        },
        setRunJobBtnState(btnState) {
            this.isButtonDisabled = btnState;
        },
        setRunClick(runClick) {
            this.runClicked = runClick;
        },
        setHideRunJob(hideRunJob) {
            this.hideRunJobBtn = hideRunJob;
        },
        setCancelJob() {
            this.cancelJobTrigger = !this.cancelJobTrigger;
        },
        setShowCancelJob(showCancelJob) {
            this.showCancelJobBtn = showCancelJob;
        },
        closeClick() {
            this.$router.push({
                name: "Jobs"
            });
        },
        closeNav(navName) {
            this.$router.push({
                name: "Job Run History",
                params: {
                    sandboxId: `${this.$route.params.sandboxId}`,
                    jobId: `${this.$route.params.jobId}`
                },
            });

        },
        onRevertClick(){
            this.showModal({ component: "RevertPromotion", data: {promotionList:this.promotionList} });
        },
        setPromotionList(list) {
            this.promotionList = list;
        },
        setShowRevertPromotion(showRevertPromotion) {
            this.showRevertPromotion = showRevertPromotion;
        },
        isAccessByUserRole(roles) {
            return this.$store.getters.userRoles.isAccessByRole(roles);
        },
        
    },
};
</script>

<style scoped>

</style>