<template>
    <div class="modal-card card">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">Add Override</h4>
            <!-- Close -->
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <CodemirrorEditor editorId="addOverride" :lineWrapping="true" v-model="override"
                        mode="text/x-sh, application/x-sh" class="form-control"></CodemirrorEditor>
                </div>
            </div>
        </div>
        <div class="card-footer text-end">
            <button data-bs-dismiss="modal" class="btn btn-secondary" aria-label="Close">
                Close</button>&nbsp;
            <button @click="onAddOverride" class="btn btn-primary">Add</button>       
        </div>
    </div>
</template>

<script>
import CodemirrorEditor from "../../components/CodemirrorEditor.vue";
import { mapActions } from "vuex";
export default {
    name: "AddOverride",
    components: { CodemirrorEditor },
    data() {
        return {
            override: ""
        }
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        }
    },
    methods: {
        ...mapActions({
            updateOverrideFile: "sandbox/updateOverrideFile"
        }),
        async onAddOverride() {
            let toastoptions = ""
            if (this.override !== "") {
                let yaml = { "override": this.override }
                let payload = { name: this.modalData.jobName, payload: yaml }
                await this.updateOverrideFile(payload).then(() => {
                    toastoptions = {
                        message: "Created override.yaml file successfully.",
                        type: "success"
                    }
                    this.modalData.fetchOverrideFile();
                    this.$router.push({
                        name: "Sandbox Job Override",
                        params: {
                            sandboxId: `${this.$route.params.sandboxId}`,
                            jobId: `${this.$route.params.jobId}`
                        },
                    });
                }).catch((error) => {
                    if (error.response.data.error)
                        this.error = error.response.data.error
                    else
                        this.error = error.message
                    toastoptions = {
                        message: this.error || "Error in creating override file.",
                        type: "error"
                    }
                });
                this.$store.dispatch('addToast', toastoptions);
                this.$store.dispatch('hideModal')
            } else {
                toastoptions = {
                    message: "Override file cannot be empty",
                    type: "error"
                }
                this.$store.dispatch('addToast', toastoptions);
            }
        }
    },

}
</script>

<style></style>