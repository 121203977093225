<template>
    <div
        class="modal fade"
        id="modalBasic"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
    >
        <div :class="['modal-dialog modal-dialog-centered',(modalData && modalData.screenlarge)?' modal-xl modal-xxl':' modal-lg']" role="document">
            <div class="modal-content">
                <component v-if="modalShown" :is="$store.state.modalComponent" :modalShown="modalShown" :modalData="modalData" :key="modalKey" :modalValue="modalValue"/>
            </div>
        </div>
    </div>
</template>

<script>
import BootstapModal from 'bootstrap/js/dist/modal';

//Possible components
import AddUser from './modals/AddUser.vue';
import AddTagTemplate from './modals/AddTagTemplate.vue';
import AddTagTemplateWizard from './modals/AddTagTemplateWizard';
import GenerateToken from './modals/GenerateToken.vue';
import RevokeTokens from './modals/RevokeTokens.vue';
import DeleteClients from './modals/DeleteClients.vue';
import DeleteUsers from './modals/DeleteUsers.vue';
import DeleteClass from './modals/DeleteClass.vue';
import DeleteTagTemplates from './modals/DeleteTagTemplates.vue';
import AddClient from './modals/AddClient.vue';
import AddClass from './modals/AddClass.vue';
import Confirm from './modals/Confirm.vue';
import DeleteSandboxes from './modals/DeleteSandboxes.vue';
import DeleteSandboxJobs from './modals/DeleteSandboxJobs.vue';
import AddSandbox from './modals/AddSandbox.vue';
import AddSandboxJob from './modals/AddSandboxJob.vue';
import CodemirrorModal from './modals/CodemirrorModal.vue';
import DiffViewModal from './modals/DiffView.vue';
import AddEdgeHost from './modals/AddEdgeHost';
import DeleteDomainRecords from './modals/DeleteDomainRecords.vue'
import ScriptTagView from './modals/ScriptTagView';
import CopyTemplateToMyLibrary from './modals/CopyTemplateToMyLibrary';
import AddInitiatorField from './modals/AddInitiatorField';
import DeleteInitiatorFields from './modals/DeleteInitiatorFields';
import AddQuery from './modals/AddQuery';
import AddParam from './modals/AddParam.vue';
import AddCredential from './modals/AddCredential.vue';
import DeleteCredentials from './modals/DeleteCredentials.vue';
import CopyPixel from './modals/CopyPixel.vue';
import PromoteJob from './modals/PromoteJob.vue';
import AddEncryptedURL from './modals/AddEncryptedURL.vue';
import DeleteEncryptedURLs from './modals/DeleteEncryptedURLs.vue';
import CopyCurl from './modals/CopyCurl.vue'
import CloneSandboxJob from './modals/CloneSandboxJob.vue';
import ConfigChanges from './modals/ConfigChanges.vue';
import UploadFile from './modals/UploadFile.vue';
import CustomComponent from './modals/CustomComponent.vue';
import ViewLog from './modals/ViewLog.vue';
import AddSandboxCredentials from './modals/AddSandboxCredentials.vue';
import DetachSandboxCredentials from './modals/DetachSandboxCredentials.vue';
import DeleteSandboxUsers from './modals/DeleteSandboxUsers.vue';
import AddSandboxUser from './modals/AddSandboxUser.vue'
import AddInitiator from './modals/AddInitiator.vue';
import DeleteInitiators from './modals/DeleteInitiators.vue';
import AddClientSubscription from './modals/AddClientSubscription.vue';
import EditClientSubscription from './modals/EditClientSubscription.vue';
import DeleteClientProducts from './modals/DeleteClientProducts.vue';
import AddProduct from './modals/AddProduct.vue';
import AddEntitlement from './modals/AddEntitlement.vue';
import DeleteProducts from './modals/DeleteProducts.vue';
import DeleteEntitlements from './modals/DeleteEntitlements.vue';
import AddProductEntitlement from './modals/AddProductEntitlement.vue';
import DeleteProductEntitlements from './modals/DeleteProductEntitlements.vue';
import EditProductEntitlement from './modals/EditProductEntitlement.vue';
import ImportNamedQuery from './modals/ImportNamedQuery.vue';
import AddHandler from  './modals/AddHandler.vue';
import DeleteHandlers from './modals/DeleteHandlers.vue';
import AddHandlerRequest from  './modals/AddHandlerRequest.vue';
import DeleteHandlerRequests from './modals/DeleteHandlerRequests.vue';
import ShowHandlerExecution from './modals/ShowHandlerExecution.vue'
import PrivacyConfig from './modals/PrivacyConfig.vue';
import DeletePrivacyConfig from './modals/DeletePrivacyConfig.vue';
import AddJobTemplate from './modals/AddJobTemplate.vue';
import DeleteJobTemplate from './modals/DeleteJobTemplate.vue';
import AddIDRJob from './modals/AddIDRJob.vue';
import AddJobTemplateGroup from './modals/AddJobTemplateGroup.vue';
import AddJobTemplateMap from './modals/AddJobTemplateMap.vue';
import DeleteJobTemplateGroupMappings from './modals/DeleteJobTemplateGroupMappings.vue';
import SaveJobTemplateMap from './modals/SaveJobTemplateMap.vue';
import AccessTokenWarning from './modals/AccessTokenWarning.vue';
import AddOverride from './modals/AddOverride.vue';
import RevertPromotion from './modals/RevertPromotion.vue';

export default {
    components: { AddUser, AddTagTemplate, AddTagTemplateWizard, GenerateToken, DeleteClients, DeleteUsers, DeleteClass, 
        DeleteTagTemplates, AddClient, AddClass, Confirm, DeleteSandboxes, AddSandbox, AddSandboxJob, DeleteSandboxJobs, 
        CodemirrorModal, DiffViewModal, AddEdgeHost, ScriptTagView, CopyTemplateToMyLibrary, DeleteDomainRecords,
        AddInitiatorField, DeleteInitiatorFields, AddQuery, AddParam, AddCredential, DeleteCredentials, RevokeTokens,  
        CopyPixel, PromoteJob, AddEncryptedURL, DeleteEncryptedURLs, CloneSandboxJob, CopyCurl, UploadFile, ConfigChanges,
        CustomComponent, ViewLog, AddSandboxCredentials, DetachSandboxCredentials, AddInitiator, DeleteInitiators, DeleteSandboxUsers, AddSandboxUser,
        AddProduct, AddEntitlement, DeleteProducts, DeleteEntitlements, AddProductEntitlement, DeleteProductEntitlements, EditProductEntitlement,
        AddClientSubscription, EditClientSubscription, DeleteClientProducts, ImportNamedQuery, AddHandler, DeleteHandlers, AddHandlerRequest,
        DeleteHandlerRequests, ShowHandlerExecution, PrivacyConfig, DeletePrivacyConfig, AddJobTemplate, DeleteJobTemplate, AddIDRJob, AddJobTemplateGroup,
        AddJobTemplateMap, DeleteJobTemplateGroupMappings, SaveJobTemplateMap, AccessTokenWarning, AddOverride, RevertPromotion },
        
    name: "Modal",
    data() {
        return {
            modalShown: false,
            modalEl: null,
            modal: null,
            modalKey: null,
            modalData: this.$store.state.modalData,
            modalValue:this.$store.state.modalValue
        }
    },
    mounted() {
        this.modalEl = document.getElementById("modalBasic");
        this.modal = new BootstapModal(this.modalEl);
        this.modalData = this.$store.state.modalData;
        this.modalValue = this.$store.state.modalValue;
        let self = this;
        this.modalEl.addEventListener('shown.bs.modal', (e)=>{
            self.modalShown = true;
            const firstInput =  self.modalEl.querySelector('input');
            if(firstInput) {
                firstInput.focus();
            }  
           
        });
        this.modalEl.addEventListener('hidden.bs.modal', (e)=>{
            self.modalShown = false;
        });        
    },
    created() {
        let self = this;
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === "setShowModal") {
                if (state.showModal === true) {
                    self.modalShown = true;
                    self.modalKey = Math.floor(Math.random() * 1000); //force reset component
                    self.modal.show()
                } else {
                    self.modal.hide()
                }
                self.modalData = state.modalData
                self.modalValue = state.modalValue
            }
        });
    },
    beforeUnmount() {
        this.unsubscribe();
    }
};
</script>

<style scoped>
.modal-xxl{
    min-width:90%;
}
</style>