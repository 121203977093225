<template>
    <div class="promotion-details left-nav-collapsible" v-show="!loading && statusObj">        
        <div class="row">
            <div v-if="detailPageOpen" id="detail-page-open">   
                <button class="btn details-open-btn" type="button" @click="onDetailPageOpenClose(false)">
                    <span> <i class="fa fa-caret-right"></i></span>
                </button>
            </div>  
            <TransitionGroup :name="!loading?'slide':''" key="detail">
                <div v-if="showCloseBtn && !detailPageOpen" id="detail-page-close" key="close">
                    <button class="btn text-start details-open-btn" type="button" @click="onDetailPageOpenClose(true)">
                        <span> <i class="fa fa-caret-left"></i></span>
                    </button>
                </div>          
                <div v-if="!detailPageOpen" id="details-col" @mouseover="onDetailPageHover(true)" key="details-col" >                               
                    <div class="card" >
                        <div class="card-header">
                            <h5 class="card-header-title">Test Params</h5>
                            <button type="button" data-bs-toggle="collapse" href="#collapse-env-params-div" aria-expanded="true" aria-controls="collapse-env-params-div" id="collapse-btn-1" class="btn  btn-sm"> <i class="fa fa-chevron-up float-end"></i></button>
                        </div>
                        <div id="collapse-env-params-div" class="collapse show" aria-labelledby="collapse-btn-1" >
                            <div class="card-body pt-2 mb-5">
                                <div class=""> 
                                    <div class="form-group">
                                        <label ><h5>Tag</h5></label>
                                        <input type="text" class="form-control" id="tagInput" placeholder="Enter tag" v-model="tag">
                                    </div> 
                                    <div class="form-group">
                                        <label ><h5>Image</h5></label>
                                        <input  class="form-control" id="tagInput"  placeholder="Enter image" v-model="image"/>
                                    </div>
                                </div>
                                <h5 class="py-1">Env params</h5>
                                <div class="">                                    
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-nowrap">
                                            <thead>
                                                <tr class="">
                                                    <th class="col-sm-5">Param</th>
                                                    <th class="col-sm-5">Input/Macro</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(
                                                        queryParam, index
                                                    ) in envParams" :key="index">
                                                    <td class="col-sm-5 p-2">
                                                        <div v-if="!queryParam.name" >
                                                            <input style="padding: 1px 2px;" 
                                                                    class="form-control" type="text" 
                                                                    v-model="envParams[index].param"
                                                            />
                                                        </div>
                                                        <div v-else>
                                                            {{ queryParam.param }}
                                                        </div>
                                                    </td>
                                                    <td class="col-sm-5 p-2">
                                                        <div v-if="queryParam.disable">
                                                            {{ queryParam.input }}
                                                        </div>
                                                        <div v-else>
                                                            <input style="padding: 1px 2px;"
                                                                class="form-control" type="text" v-model="envParams[index].input" />
                                                        </div>
                                                    </td>
                                                    <td class="col-sm-1">
                                                        <i  @click="removeQueryParamField(index)" class="fa fa-regular fa-trash-can"></i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>                        
                                    </div>
                                    <div class="">
                                        <button class="btn btn-sm btn-primary float-end m-3" @click="onAddQueryParam">Add Env Param</button>
                                    </div>
                                </div>                         
                            </div>
                        </div>    
                        <div class="card-header">
                            <h5 class="card-header-title">Details</h5>
                            <button type="button" data-bs-toggle="collapse" href="#collapse-props-div" aria-expanded="false" aria-controls="collapse-props-div" id="collapse-btn-2" class="btn  btn-sm collapsed"> <i class="fa fa-chevron-up float-end"></i></button>
                        </div>
                        <div id="collapse-props-div" class="collapse" aria-labelledby="collapse-btn-2" >
                            <div class="card-body pt-0">
                                <div class="list-group list-group-flush">
                                    <div v-for="(item, index) in listItems" :key="index" class="list-group-item">
                                        <div class="row">
                                            <div class="col">
                                                <!-- Title -->
                                                <small>{{ item.title }}</small>
                                            </div>
                                            <div class="col-auto">
                                                <small v-if="item.title=='Status'" :class="statusObj.class" >
                                                <strong>{{ promotionObj[item.data] }}</strong>
                                                </small>
                                                <small v-else-if="item.data == 'dst_job_name' && promotionObj['new_job']" class="text-muted" >
                                                <strong>{{ promotionObj[item.data] }}</strong> <span class="badge badge-primary">New</span>
                                                </small>
                                                <small v-else-if="item.data == 'created_at'" class="text-muted" >
                                                <strong>{{ DateTimeFormat(promotionObj[item.data]) }}</strong><span class="badge badge-secondary">{{ convertToRelativeTime(promotionObj[item.data]) }}</span>
                                                </small>
                                                <small v-else-if="item.data == 'updated_at'" class="text-muted" >
                                                <strong>{{ DateTimeFormat(promotionObj[item.data]) }}</strong><span class="badge badge-secondary">{{ convertToRelativeTime(promotionObj[item.data]) }}</span>
                                                </small>                          
                                                <small v-else class="text-muted" >
                                                <strong>{{ promotionObj[item.data] }}</strong> 
                                                </small>
                                            </div>
                                        </div> <!-- / .row -->
                                    </div>                            
                                </div>
                            </div> 
                        </div>                                       
                    </div>
                </div> 
                <div class="col" id="main-col" @mouseover="onDetailPageHover(false)" key="main-col">
                    <div class="card" role="alert" v-if="statusObj.alertMsg">
                        <div class="card-header p-4 ">                  
                            <h4 class="card-header-title" :class="statusObj.class">
                                <span v-if="statusObj.class == 'text-success'" :class="statusObj.class"><strong>{{ statusObj.alertMsg }}</strong><button  class="btn btn-link  text-decoration-underline link-success" @click="redirectToProdJob"><strong>View job in production</strong></button></span>
                                <span v-else><i class="fa fa-circle-info" :class="statusObj.class"></i>&nbsp;<strong>{{ statusObj.alertMsg }}</strong></span>
                            </h4>
                        </div>  
                    </div>
                    <div class="card"> 
                        <div class="card-header">
                            <h5  className="card-header-title">Parameters</h5>
                            <button type="button" data-bs-toggle="collapse" href="#collapse-var-div" aria-expanded="true" aria-controls="collapse-var-div" id="header-1" class="btn  btn-sm"> <i class="fa fa-chevron-up float-end"></i></button>                
                    </div>  
                        <div id="collapse-var-div" class="collapse show" aria-labelledby="header-1">
                            <div class="card-body row">
                                <div class="col-md-12">
                                    <input-field label="Vars" name="vars" type="yaml" v-if="!loading"
                                    :editable="editMode"
                                    v-model="promotionObj['vars']" />
                                </div>
                                <div class="col-md-12">
                                    <input-field label="Schedule" 
                                    :editable="editMode"
                                    type="cron" v-model="promotionObj['schedule']" />
                                </div>  
                            </div>
                        </div>  
                    </div>
                    <div class="card" v-if="diffs || scheduleDiffObj">    
                        <div class="card-header"> 
                            <h5  className="card-header-title">Changes</h5>
                            <button type="button" data-bs-toggle="collapse" href="#collapse-changes-div" aria-expanded="true" aria-controls="collapse-changes-div" id="header-2" class="btn  btn-sm"> <i class="fa fa-chevron-up float-end"></i></button>
                        </div>                    
                        <div id="collapse-changes-div" class="collapse show" aria-labelledby="header-2">              
                            <div class="card-body">
                                <div v-if="this.scheduleDiff">
                                    <span> Old Schedule <i class="fa fa-arrow-right-long"></i> New Schedule</span>  
                                    <Diff
                                        mode="split"
                                        theme="light"
                                        language="plaintext"
                                        :prev="scheduleDiffObj.prev"
                                        :current="scheduleDiffObj.current"
                                        :folding="scheduleDiffObj.folding"
                                    />
                                </div>                        
                                <div v-html="fileDiffHtml" />
                            </div>
                        </div>                    
                    </div> 
                    <div v-else-if="loading" class="text-center">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>  
                </div>
            </TransitionGroup>                  
        </div>        
    </div>
    <div v-show="loading || !statusObj"  class="text-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>

</template>

<script>
import InputField from "../../../components/InputField.vue";
import * as Diff2Html from 'diff2html';
import 'diff2html/bundles/css/diff2html.min.css';
import axios from 'axios';
import dayjs from 'dayjs';
import { relativeTime, DateTimeFormat } from '../../../utils/commonFunction';
export default {
    components: { InputField },
    data() {
        return {
            promotionId: this.$route.params.promotionId,
            listItems: [
                { data: "status", title: "Status"  },
                { data:"description", title:"Description" },
                { data: "src_job_run_id" , title: "Src Job Run ID"},
                { data: "dst_sandbox_name" , title: "Dst Sandbox Name"},
                { data: "dst_job_name" , title: "Dst Job Name"},
                { data: "created_by" , title: "Created By"},
                { data: "updated_by" , title: "Updated By"},
                { data: "created_at" , title: "Created At"},
                { data: "updated_at" , title: "Updated At"},

            ],
            promotionObj: this.promotion,
            diffs: '',
            scheduleDiff: null,
            loading: false,
            detailPageOpen: false,
            showCloseBtn:false,

        }
    },
    computed: {
        fileDiffHtml() {
            if(this.diffs) { 
                const diffRes = Diff2Html.html(this.diffs, {
                    drawFileList: false,
                    matching: 'lines',
                });
                return diffRes;
            } 
            return null;  
        },
        scheduleDiffObj() {
            if(this.scheduleDiff) { 
                return {
                    prev: JSON.stringify(this.scheduleDiff.old, null, " "),
                    current: JSON.stringify(this.scheduleDiff.new, null, " ")               
                };
            }
            return null;   
        },
        statusObj() {
            let status =  this.promotion.status.toLowerCase();
            if (status == 'succeeded') {
                return {class:'text-success', alertMsg: 'Promotion is completed!'};
            } else if (status == 'in_progress') {
                //When there is no diff , promotion needs to be cancel or remake it.
                if(!this.loading && !this.scheduleDiff && this.diffs == ''){
                    return { class: 'text-primary', alertMsg: 'No changes detected. Cancel this promotion or update.'};
                } else {
                    return { class: 'text-primary', alertMsg: 'Test and complete this promotion!'};
                }               
            } else if (status == 'cancelled') {
                return { class: 'text-danger', alertMsg: 'Promotion request is cancelled!' };                
            } else if(status == 'submitted'){
                return { class: 'text-warning', alertMsg: 'Accept this promotion request to test and complete!' }; 
            } else {
                return { class: '', alertMsg: null }; 
            }            
        },
        generatedUrl() {                     
            let urlTemplate = `${this.$store.state.lakeviewUrl}/v1/cids/${this.$store.state.currentClient.cid}/sandboxes/${this.$route.params.sandboxId}/promotions/${this.promotionId}/executions`
            if(this.tag)
                urlTemplate = `${urlTemplate}?tag=${this.tag}`;
            
            return urlTemplate;
        },
        tag: {
            get() {
                return this.$store.state.sandbox.jobRunParams[this.promotionId].tag;
            },
            set(val) {
                this.$store.dispatch('sandbox/setJobTag',{jobName: this.promotionId,tag: val});       
            }
        },
        image: {
            get() {
                return this.$store.state.sandbox.jobRunParams[this.promotionId].image;
            },
            set(val) {
                this.$store.dispatch('sandbox/setJobImage',{jobName: this.promotionId,image: val});       
            }
        },
        envParams: {
            get() {
                return this.$store.state.sandbox.jobRunParams[this.promotionId].envParams;
            },
            set(val) {
                this.$store.dispatch('sandbox/setJobEnvParams',{jobName: this.promotionId, envParams: val});       
            }
        }    
    },
    watch: {
        savePromotionTrigger() {
            this.savePromotion();
        },
        getConfigDiffTrigger() {            
            this.getConfigDiff();
        },
        promotion(newPromotionObj) {
            this.promotionObj = newPromotionObj;
        },
        testPromotionTrigger() {            
            this.testPromotion();
        },
    },
    props: [ "promotion", "editMode", "savePromotionTrigger", "testPromotionTrigger","getConfigDiffTrigger"],
    beforeMount() {       
        this.getConfigDiff();
        this.$store.dispatch('sandbox/setDefaultJobRunParams',this.promotionId);          
    },
    mounted() {
    },
    methods: {
        DateTimeFormat,
        async getConfigDiff() {
            let self = this;   
            let response; 
            this.loading=true;
            
            await axios.get(`${self.$store.state.lakeviewUrl}/v1/cids/${self.$store.state.currentClient.cid}/promotions/${self.$route.params.promotionId}/diff`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    }
            }).then((response)=> {
                this.diffs = "";
                for (const key in response.data.files_diff) {
                    if(response.data.files_diff[key].patch==''){
                        response.data.files_diff[key].patch = '@@ -0,0 +1 @@';
                    }                  
                    this.diffs += `--- a/ ${response.data.files_diff[key].name}\n+++ b/${response.data.files_diff[key].name}\n`+response.data.files_diff[key].patch
                    this.diffs += '\n';
                }
                this.loading=false
                const scheduleDiff = response.data.schedule_diff;
                this.scheduleDiff = (scheduleDiff  && scheduleDiff.new !== scheduleDiff.old)?scheduleDiff :null;
               
                if(!this.scheduleDiff && this.diffs == '' ) {                    
                    this.$emit('hideTestBtn', true);
                    this.$emit('showCompleteBtn', false);
                } else if(this.scheduleDiff && this.diffs == '') { // When only schedule is changed, hide test btn
                    this.$emit('hideTestBtn', true);
                    this.$emit('showCompleteBtn', true);
                } else {  
                    this.$emit('hideTestBtn', false);                  
                    this.$emit('showCompleteBtn', true);
                }

            }).catch((error) => {
                this.diffs = null;
                this.scheduleDiff = null;
                this.loading=false
                if (error.response.data.error) 
                    return error.response.data.error
                else if (error.response.data.message) 
                    return error.response.data.message
                else 
                    return error.message
            });
        },
        savePromotion() {
            let toastoptions;
            let self = this;
            let updatedPromotionObj ={status:this.promotionObj.status, vars: this.promotionObj.vars, schedule:this.promotionObj.schedule};
            axios.post(`${this.$store.state.lakeviewUrl}/v1/cids/${this.$store.state.currentClient.cid}/sandboxes/${this.$route.params.sandboxId}/promotions/${this.promotionId}/update`, updatedPromotionObj, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`,
                },
            }).then((response) => {
                self.getConfigDiff();
                toastoptions = {
                    message: "Promotion updated successfully.",
                    type: "success"
                }             
                
            }).catch((error)  => {
                if (error.response && error.response.data && error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;

                toastoptions = {
                    message: this.error || "Something went wrong when updating promotion. Please contact support.",
                    type: "error"
                }
            }).finally(() => {                
                this.$emit('readPromotionDetail');
                this.$emit('triggerEditMode', false);
                this.$store.dispatch('addToast', toastoptions);
            });  
        },
        redirectToProdJob() {
            this.$router.push({
                name: "Job Configuration",
                params: {
                    sandboxId: `${this.promotion.dst_sandbox_name}`,
                    jobId: `${this.promotion.dst_job_name}`
                },
            });
        },
        async testPromotion() {
            this.$emit('setRunClick', true);
            this.runClicked = true;
            let self = this;

            var payload = {};
            
            if(this.image)
                payload.image = this.image;
            
            if(this.envParams.length) {
                let envParamsObj = {};
                if (this.envParams) {
                    for (let i = 0; i < this.envParams.length; i++) {
                        const field = this.envParams[i];
                        if(field.input)
                        envParamsObj[field.param] = field.input;
                    }
                } 
                payload.env_params = envParamsObj;  
            }

            await axios.post(this.generatedUrl, payload, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    }
            }).then((response)=> { 
                this.$emit('setRunClick', false);
                const executionDetails = response.data;
                self.$store.dispatch("sandbox/fetchPromotionExecutions", {promotionId: self.promotionId});
                this.$router.push({
                    name: "Promotion Execution Details",
                    params: {
                        sandboxId: `${this.$route.params.sandboxId}`,
                        promotionId: `${this.$route.params.promotionId}`,
                        executionId: executionDetails.execution_id,
                        jobId: self.promotion.dst_job_name
                    },
                });    
              
            }).catch((error) => {
                this.$emit('setRunClick', false);
                if (error.response && error.response.data && error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;

                let toastoptions = {
                    message: this.error || "Something went wrong while executing a promotion. Please contact support.",
                    type: "error"
                }
                this.$store.dispatch('addToast', toastoptions);
            });
        }, 
        onAddQueryParam() {
            this.envParams.push({
                name: null,
                param: null,
                input: null,
                deletable: true
            })
        },
        removeQueryParamField(index) {
            this.envParams.splice(index, 1);
        }, 
        convertToRelativeTime(timestamp){
            return relativeTime(Date.now(), dayjs(timestamp));
        }, 
        onDetailPageOpenClose(value){
            this.showCloseBtn = false;
            this.detailPageOpen = value;
        },
        onDetailPageHover(value){
            this.showCloseBtn = value;
        }     
    },

    emits: ['triggerEditMode','setRunClick', 'hideTestBtn','showCompleteBtn', 'readPromotionDetail']
  
}
</script>

<style scoped>
.promotion-details .card-header {
    background-color: #F9FBFD;
    text-transform: uppercase;    
    color: #95AAC9;
    height:40px;
}

.promotion-details .collapsed .fa {
  transform: rotate(180deg);
}
</style>