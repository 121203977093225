<template>
    <div class="modal-card card">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">Revert Promotion</h4>
            <!-- Close -->
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-10">
                    <div class="mb-3">
                        <h3 for="promotionInput" class="form-label">Choose a Promotion</h3>
                        <select class="form-select" v-model="promotion">
                            <option value="" disabled>
                                Select a promotion id
                            </option>
                            <option v-for="option in revertList" :key="option.id" :value="option">
                                {{ option.id + " - " + DateTimeFormat(option.created_at)}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer text-end">
            <button data-bs-dismiss="modal" class="btn btn-secondary" aria-label="Close">
                Close</button>&nbsp;
            <button class="btn btn-primary" id="myButton" @click="onRevertClick()">
                <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <div v-else>
                    Revert
                </div>
            </button>&nbsp;
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { DateTimeFormat } from '../../utils/commonFunction';
export default {
    data() {
        return {
            selectedCls: null,
            selectedPixel: null,
            loading: false,
            promotion: "",
            promotionList: this.modalData.promotionList
        }
    },
    computed: {
        revertList() {
            if (this.promotionList) {
                let revertList = this.promotionList.filter((item) => {
                    return item.status.includes("SUCCEEDED")
                })
                revertList.shift()
                return revertList
            } else return []
        }
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    methods: {
        DateTimeFormat,
        ...mapActions({            
            showModal: "showModal",
            revertPromotion: "sandbox/revertPromotion",
        }),
        onRevertClick() {
            const self = this;
            if (self.promotion != "") {
                this.showModal({
                    component: "confirm",
                    data: {
                        title: "Revert Promotion",
                        message: `Are you sure you want to revert promotion ${self.promotion.id}?`,
                        async confirm() {
                            try {
                                self.loading=true;
                                await self.revertPromotion({
                                    promotionId: self.promotion.id,
                                    sandboxId: self.promotion.dst_sandbox_name,
                                    jobName: self.promotion.dst_job_name
                                }).then((response) => {
                                    if (response.data) {
                                        let toastoptions = {
                                            message: "Reverted Job promotion successfully.",
                                            type: "success"
                                        }
                                        self.promotion = response.data;
                                        self.promotionId = response.data.id;
                                        self.$route.params.promotionId = self.promotionId
                                        self.$store.dispatch('addToast', toastoptions);
                                        self.$router.push({
                                            name: "Promotion Overview",
                                            params: {
                                                sandboxId: `${self.$route.params.sandboxId}`,
                                                promotionId: `${response.data.id}`,
                                            },
                                        });
                                    }
                                })

                            } catch (error) {
                                if (error?.response?.data.error)
                                    self.error = error.response.data.error;
                                else self.error = error.message;

                                let toastoptions = {
                                    message: self.error || "Something went wrong while cancelling promotion. Please contact support.",
                                    type: "error"
                                }
                                self.$store.dispatch('addToast', toastoptions);
                                return;
                            } finally {
                                self.loading=false;
                                self.$store.dispatch('hideModal');
                            }
                        },
                    },
                });
            } else {
                let toastoptions = {
                    message: "Select a promotion id",
                    type: "error"
                }
                self.$store.dispatch('addToast', toastoptions);
            }
        }
    }

}
</script>

<style></style>