<template>
	<div v-if="formattedMetricData">
		<div v-for="(metricDataObj, key) in formattedMetricData" :key="key">
			<div class="card mb-4" v-if="metricDataObj.data || (jobKind == 'MAPPER-ΜBATCH' && key == 'amdp-metric')">
				<div class="card-body">
					<!-- First Row -->
					<div class="row mb-3">
						<div class="col-12 col-lg-3 mb-3 mb-lg-0">
							<h3 v-if="key && metricDataObj.data" class="mb-0">
								{{ key.replace(/-/g, " ").replace(/amdp/gi, "aMDP") }}
							</h3>
						</div>
						<div class="col-12 col-lg-9">
							<!-- Time Range Row -->
							<div class="d-flex flex-wrap justify-content-lg-end">
								<!-- Time Range -->
								<div v-if="jobKind == 'MAPPER-ΜBATCH' && key == 'amdp-metric'" 
                                     class="mb-3">
									<div class="input-group" style="width: 250px;">
										<span class="input-group-text">Time Range</span>
										<InputField colField="mb-0" :editable="true" :label="null" :showLabel="false"
											:options="timeRangeOptions" type="select" :modelValue="timeRange"
											@change="timeRangeChange($event)" />
									</div>
								</div>

								<!-- Custom Range Controls -->
								<div v-if="timeRange === 'custom_range' && key == 'amdp-metric'"
									class="d-flex flex-wrap ">
									<!-- Date Pickers -->
									<div class="d-flex align-items-center mb-3">
										<div class="input-group" style="width: 250px;">
											<span class="input-group-text">
												<i class="fas fa-calendar"></i>
											</span>
											<InputField colField="mb-0" :modelValue="customStartTime" :showLabel="false"
												type="calendar" :input-config="dateConfig"
												@update:modelValue="startTimeChange($event)" />
										</div>
										<div class="mx-2">-</div>
										<div class="input-group ms-3" style="width: 250px;">
											<span class="input-group-text">
												<i class="fas fa-calendar"></i>
											</span>
											<InputField colField="mb-0" :modelValue="customEndTime" :showLabel="false"
												type="calendar" :input-config="dateConfig"
												@update:modelValue="endTimeChange($event)" />
										</div>
									</div>

									<!-- Interval and Apply -->
									<div class="d-flex align-items-center mb-3">
										<div class="input-group me-3" style="width: 250px;">
											<span class="input-group-text">Aggregate Interval</span>
											<InputField colField="mb-0" :editable="true" :label="null" :showLabel="false"
												:options="intervalOptions" type="select" :modelValue="aggInterval"
												@change="aggIntervalChange($event)" />
										</div>
										<button class="btn btn-primary" style="width: 100px;" @click="getCountV2Metrics">
											Apply
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div v-if="metricDataObj.data" class="position-relative">
						<div class="position-absolute top-2 end-0 mt-4 me-2">
							<div class="custom-control form-check form-switch">
								<input class="form-check-input" type="checkbox" role="switch"
									:id="`customSwitch_${key}`" :checked="showDataInUnits[key]"
									@change="updateShowDataInUnits(key, $event.target.value)" />
								<label class="form-check-label" :for="`customSwitch_${key}`">
									Show Abbreviated Data
								</label>
							</div>
						</div>
						<div v-if="loading">
							<div class="d-flex justify-content-center">
								<div class="spinner-border" role="status">
									<span class="visually-hidden">Loading...</span>
								</div>
							</div>
						</div>
						<Datatable class="mb-3" v-else-if="metricDataObj.data" :tableId="`${executionId}-${key}`"
							:showLoading="metricDataObj === null" :compact="true" :columns="metricDataObj.headers"
							:data="metricDataObj.data" :paging="metricDataObj.data.length > 10"></Datatable>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else>
		<h4>No metric data found!</h4>
	</div>
</template>







<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Datatable from "../Datatable.vue";
import InputField from "../../components/InputField.vue";
import { formatBytes, DateTimeFormat } from "../../utils/commonFunction";
dayjs.extend(utc);
export default {
	components: { Datatable, InputField },
	data() {
		return {
			loading: false,
			metricColumns: [],
			showDataInUnits: {},
			formattedMetricData: null,
			timeRangeOptions: [
				{ value: "last_hour", label: "Last Hour" },
				{ value: "overall", label: "Overall" },
				{ value: "custom_range", label: "Custom" },
			],
			//5m, 15m, 30m, 1h
			intervalOptions: [
				{ value: "5m", label: "5m" },
				{ value: "15m", label: "15m" },
				{ value: "30m", label: "30m" },
				{ value: "1h", label: "1h" },
				{ value: null, label: "None" }
			],
			startTimeRange: null,
			endTimeRange: null,
			dateConfig: {
				enableTime: true,
				altInput: true,
				dateFormat: "Y-m-d H:i:S",
				altFormat: "M j, Y h:i:S K", // This format is for display
				formatDate: (date, format) => {
					if (format === "Y-m-d H:i:S") {
						// This is for the hidden input (UTC)
						return this.formatUTCDate(date);
					}
					// This is for the visible input (local time)
					return this.formatLocalDate(date);
				},
				onChange: (selectedDates, dateStr, instance) => {
					if (selectedDates[0]) {
						this.utcDate = this.formatUTCDate(selectedDates[0]);
						this.$emit("update:modelValue", this.utcDate);
					}
				},
			},
		};
	},
	methods: {
		formatData() {
			this.formattedMetricData = Object.fromEntries(
				Object.entries(this.metricData)
					.sort(([titleA], [titleB]) => {
						const order = {
							"amdp-metric": 1,
							"amdp-performance-metric": 2,
							"amdp-job-run-params": 3,
						};
						return (
							(order[titleA] || Infinity) -
							(order[titleB] || Infinity)
						);
					})
					.map(([title, value]) => {
						if (
							typeof value === "object" &&
							value.data &&
							value.headers
						) {
							// First, identify columns that have all null values
							const columnsWithData = value.headers.filter(
								(header, headerIndex) => {
									// Check if the column has any non-null values
									return value.data.some((row) => {
										const cellValue = row[headerIndex];
										return (
											cellValue !== null &&
											cellValue !== undefined &&
											cellValue !== ""
										);
									});
								}
							);
							// Filter out time and measure_name from headers
							const filteredHeaders = value.headers.filter(
								(header) =>
									header !== "time" &&
									header !== "measure_name" &&
									columnsWithData.includes(header)
							);
							return [
								title,
								{
									...value,
									headers: filteredHeaders.map((key) => {
										let self = this;
										const tempKey = key;
										let obj = {};
										if (
											key == "byte_count" ||
											key.includes("memory")
										) {
											obj = {
												data: key,
												title: tempKey.replace(
													/-/g,
													""
												),
												render: function (
													data,
													type,
													row
												) {
													if (!data) return "";
													if (
														!self
															.showDataInUnits[
														title
														]
													)
														return data;
													return formatBytes(
														data
													);
												},
											};
										} else if (key == "record_count") {
											obj = {
												data: key,
												title: tempKey.replace(
													/-/g,
													""
												),
												render: function (
													data,
													type,
													row
												) {
													if (!data) return "";
													if (
														!self
															.showDataInUnits[
														title
														]
													)
														return data;
													return Intl.NumberFormat(
														"en",
														{
															notation:
																"compact",
															roundingMode:
																"floor",
															maximumFractionDigits: 2,
														}
													).format(data);
												},
											};
										} else {
											obj = {
												data: key,
												title: tempKey.replace(
													/-/g,
													""
												),
											};
										}

										return obj;
									}),
									// Filter out time and measure_name from data
									data: value.data.map((row) => {
										const obj = {};
										row.forEach((rowValue, index) => {
											const headerKey =
												value.headers[index];
											if (
												filteredHeaders.includes(
													headerKey
												) &&
												rowValue !== null &&
												rowValue !== undefined &&
												rowValue !== ""
											) {
												obj[headerKey] = rowValue;
											}
										});
										return obj;
									}),
								},
							];
						}
						return [title, value];
					})
			);

		},
		updateShowDataInUnits(key) {
			this.showDataInUnits[key] = !this.showDataInUnits[key];
			this.formatData();
		},
		getCountV2Metrics() {			
			this.loading = true;
			this.$emit("callGetCountV2Metrics");
		},
		aggIntervalChange(interval) {
			this.$emit("onIntervalChange", interval);
		},
		timeRangeChange(timeRange) {
			this.$emit("onTimeRangeChange", timeRange);
			this.dateConfig.minDate = this.startedAt;
			this.dateConfig.maxDate = this.completedAt;
			if (timeRange == "last_hour") {
				this.getCountV2Metrics();
			} else if (timeRange == "overall") {
				this.getCountV2Metrics();
			} else {
				if (!this.customStartTime) {
					this.$emit("apply-custom-range", {
						startTime: this.startedAt,
						endTime: this.completedAt,
					});
				}
			}

		},
		startTimeChange(time) {
			this.startTimeRange = time;
			this.$emit("apply-custom-range", {
				startTime: this.formatUTCString(time),
				endTime: this.customEndTime,
			});
		},
		endTimeChange(time) {
			this.endTimeRange = time;
			this.$emit("apply-custom-range", {
				startTime: this.customStartTime,
				endTime: this.formatUTCString(time),
			});
		},
		formatUTCDate(date) {
			return (
				date.getUTCFullYear() +
				"-" +
				String(date.getUTCMonth() + 1).padStart(2, "0") +
				"-" +
				String(date.getUTCDate()).padStart(2, "0") +
				" " +
				String(date.getUTCHours()).padStart(2, "0") +
				":" +
				String(date.getUTCMinutes()).padStart(2, "0") +
				":" +
				String(date.getUTCSeconds()).padStart(2, "0")
			);
		},
		formatLocalDate(date) {
			const months = [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			];
			const year = date.getFullYear();
			const month = months[date.getMonth()];
			const day = date.getDate();
			const hours = date.getHours();
			const minutes = String(date.getMinutes()).padStart(2, "0");
			const seconds = String(date.getSeconds()).padStart(2, "0");

			// Convert hours to 12-hour format and determine AM/PM
			const period = hours >= 12 ? "PM" : "AM";
			const hours12 = hours % 12 || 12; // Convert 0 to 12 for midnight

			return `${month} ${day}, ${year} ${String(hours12).padStart(
				2,
				"0"
			)}:${minutes}:${seconds} ${period}`;
		},
		formatUTCString(utcString) {
			// Parse the input UTC string
			const date = new Date(utcString + "Z"); // Appending 'Z' to ensure it's treated as UTC

			// Format to ISO 8601 UTC string
			return date.toISOString();
		},
	},
	watch: {
		metricData: {
			handler() {
				Object.keys(this.metricData).forEach((key) => {
					this.showDataInUnits[key] = true;
				});
				this.formatData();
				this.loading = false;
			},
			deep: true,
		},
	},

	beforeMount() {
		if (!this.timeRange && this.jobKind == 'MAPPER-ΜBATCH') {
			this.timeRangeChange("overall");
		}
		// Initialize showDataInUnits with true for each key in metricData
		Object.keys(this.metricData).forEach((key) => {
			this.showDataInUnits[key] = true;
		});
		this.formatData();
	},
	props: [
		"metricData",
		"jobKind",
		"startedAt",
		"completedAt",
		"timeRange",
		"aggInterval",
		"customEndTime",
		"customStartTime",
		"executionId",
	],
};
</script>

<style scoped>
/* Extra large screens (1400px and up) */
@media (min-width: 1400px) {
    .input-group {
        width: 250px !important;
    }
    .btn {
        width: 100px !important;
    }
}

/* Large screens (1200px to 1399px) */
@media (min-width: 1200px) and (max-width: 1399px) {
    .input-group {
        width: 250px !important;
    }
    .btn {
        width: 100px !important;
    }
}

/* Medium screens (992px to 1199px) */
@media (min-width: 992px) and (max-width: 1199px) {
    .input-group {
        width: 250px !important;
    }
    .btn {
        width: 90px !important;
    }
}

/* Small screens (768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
    .input-group {
        width: 100% !important;
        max-width: 250px !important;
        margin-bottom: 1rem;
    }
    .btn {
        width: 90px !important;
    }
}

/* Extra small screens (below 768px) */
@media (max-width: 767.98px) {
    .input-group {
        width: 100% !important;
        max-width: none !important;
        margin-bottom: 1rem;
    }
    
    .btn {
        width: 100% !important;
    }

    .d-flex.flex-wrap {
        width: 100%;
    }
    
    /* Improve spacing on mobile */
    .me-2, .me-3 {
        margin-right: 0 !important;
    }
    
    .mb-3 {
        margin-bottom: 1rem !important;
    }
}


</style>

