<template>
    <basic-header
        pretitle="Aqfer Marketing Data Platform"
        title="Promotions"        
    />
    <div class="container-fluid" v-if="!loading && selectedSandbox && (sandBoxListOptions.length)"> 
        <input-field
        label="Choose a Sandbox"
        type="select"
        :options="sandBoxListOptions"
        v-model="selectedSandbox"
        />        
        <div class="card">   
            <div class="row justify-content-end">                                    
                <div  class="col-sm-3 d-flex position-absolute mt-3 me-3 justify-content-end" >
                    <div class="input-group input-group-sm">
                        <label class="input-group-text">Promotions Limit</label>
                        <input type="number" class="form-control" aria-label="limit" v-model="$store.state.sandbox.promotionsListLimit">
                        <button :disabled="promotionList===null" class="btn btn-outline-secondary" type="button" @click="readPromotionsList"><i class="fa fa-refresh" aria-hidden="true"></i></button>                    
                    </div>
                </div>           
            </div>                     
            <datatable             
                tableId="promotionList"
                :columns="columns"
                :formatColumn="formatColumn"
                :orderColumn="orderColumn"
                :data="promotionList" 
                :showLoading="promotionList===null"                 
                :compact="true"           
                :detailColumn="{renderBaseUrl: (data) => {
                                                        return `promotions/${data.id}/overview`;                                                   
                                                    },}"
            />
        </div>  
    </div>
    <div v-else-if="!loading && !sandBoxListOptions.length" class="container-fluid" >
        <div class="alert alert-secondary" role="alert">
            No Sandboxes available!
        </div>        
    </div>
    <div v-else class="text-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    
</template>
<script>
import BasicHeader from "../../components/BasicHeader.vue";
import InputField from "../../components/InputField.vue";
import Datatable from "../../components/Datatable.vue";
import { mapActions, mapState } from "vuex";
import axios from "axios";
export default {
    name: "Promotions",
    components: { Datatable, BasicHeader, InputField },
    data() {
        return {
            columns: [            
                { data: "id" },
                { data: "src_sandbox_name", title: "Src Sandbox" },
                { data: "src_job_name", title: "Src Job"},
                { data: "dst_job_name", title: "Dst Job"},
                { data: "status" },
                { data: "created_by" },
                { data: "created_at" }       
            ],
            orderColumn: [7, "desc"],
            formatColumn: [
                {
                    index: 7,
                    type: "datetime",
                },
            ],          
            promotionList: null,
            loading: true
        };
    }, 
    computed: {
        selectedSandbox: {
            get() {
                return this.$store.state.sandbox.currentSandbox;
            },
            set(value) {
                if(value) {                   
                    this.$store.dispatch('sandbox/setCurrentSandbox',value);  
                    this.$router.push({
                            name: "Promotions",
                            params: {
                                sandboxId: `${value}`
                            },
                        });    

                }
                       
            }

        },
        sandBoxListOptions() {
            return this.$store.state.sandbox.prodV2Sandboxes? this.$store.state.sandbox.prodV2Sandboxes.map((sandbox) => {
                return {"label": sandbox.name, "value": sandbox.name };                
            }) : [];
        }
    }, 
    methods: {
      ...mapActions({
            fetchSandboxPromotions: "sandbox/fetchSandboxPromotions",         
        }),
        async readPromotionsList() { 
            let modifiedList = [];          
            let promotionList = await this.fetchSandboxPromotions(this.$store.state.sandbox.promotionsListLimit); 
            this.loading=false;
            promotionList.map((promotion)=>{
                let status ="";
                if(promotion.status.toLowerCase()==="succeeded"){
                    status = "<span class='text-success'>●</span> ";
                } else if(promotion.status.toLowerCase()==="failed"){
                    status = "<span class='text-danger'>●</span> ";
                } else if(promotion.status.toLowerCase()==="in_progress"){
                    status = "<span class='text-primary'>●</span> ";
                } else if(promotion.status.toLowerCase()==="submitted"){
                    status = "<span class='text-warning'>●</span> ";
                } else if(promotion.status.toLowerCase()==="cancelled"){
                    status = "<span class='text-secondary'>●</span> ";
                }
                promotion["status"] = status + promotion.status;
                modifiedList.push(promotion);
            })
            let cache = this.$store.state.datatableCache;
            if (cache["promotionList"]) {
                cache["promotionList"] = null
            }
            this.$store.dispatch('setDatatableCache', cache)
            this.promotionList = modifiedList;
        }      
        
    },
    async beforeMount() {
        this.readPromotionsList(); 
    }

}
</script>

<style>

</style>